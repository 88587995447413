import { ContentDelivery, useContentDeliveryAPI, useEpiserver } from '@episerver/spa-core';
import { SubMenuDto } from '../../../../Models/Interfaces/ApiResponse/TransportBlockApiResponse';
import { OldDomainPageType } from '../../../../Models/Content/OldDomainPageData';
import { useEffect, useState } from 'react';
import IContent from '@episerver/spa-core/dist/Models/IContent';
import { GoToTopAnchor } from './GoToTopAnchor';
import { SkipToContent } from './SkipToContent';
import OldBreadcrumbs from './OldBreadcrumbs';
import { GoToTopLink } from './GoToTopLink';
import { getDescription } from '../../Utils';
import OldFeedback from './OldFeedback';
import { Helmet } from 'react-helmet-async';
import OldFooter from './OldFooter';
import MainMenu from './MainMenu';
import SiteTop from './SiteTop';
import axios from 'axios';
import { ArticlePageType } from 'Models/ArticlePageData';
import { SiteMapPageType } from 'Models/SiteMapPageData';
import { OldSearchPageType } from 'Models/OldSearchPageData';
import { DummyPageType } from 'Models/DummyPageData';
import { TransportPageType } from 'Models/Content/TransportPageData';
import { OldBerammingSokPageType } from 'Models/OldBerammingSokPageData';
import { TagPageType } from 'Models/TagPageData';
import { RootLayoutProps } from '../../../Layout/RootLayoutProps';
import { General, useTranslation } from 'app/hooks/useTranslations';

function ShouldHideDidYouFind(currentContent: CurrentContent): boolean {
    if ('hideDidYouFind' in currentContent) {
        return currentContent.hideDidYouFind.value;
    }
    return true;
}

// Every possible OldDomstol Page Types
export type CurrentContent =
    | OldDomainPageType
    | ArticlePageType
    | OldSearchPageType
    | SiteMapPageType
    | DummyPageType
    | TransportPageType
    | OldBerammingSokPageType
    | TagPageType;

const apiUrl = '/api/episerver/v3/mainmenu';

export const HoyesterettLayout = (
    props: RootLayoutProps & {
        currentContent: CurrentContent;
        layoutSettings?: ContentDelivery.StringProperty;
        pathname?: string;
        children?: React.ReactNode;
    }
) => {
    const { currentLanguage, currentContent, layoutSettings } = props;
    const { t } = useTranslation();
    const api = useContentDeliveryAPI();
    const [domainPage, setDomainPage] = useState<OldDomainPageType>();
    const [mainMenu, setMainMenu] = useState<SubMenuDto[]>();
    const context = useEpiserver();

    const language = currentContent?.language?.name ?? 'no';

    useEffect(() => {
        const updateMenu = (domainPage: OldDomainPageType) => {
            if (domainPage.cssBodyClass?.value) {
                document.body.classList.add(domainPage.cssBodyClass?.value);
            }

            const url = new URL(domainPage?.contentLink?.url);

            axios
                .get<SubMenuDto[]>(`${apiUrl}?url=${url.pathname}&language=${domainPage.language?.name}`)
                .then((childrenList) => {
                    setMainMenu(childrenList.data);
                });
        };

        const getDomainPageFromAncestors = (currentContent: IContent) => {
            api.Language = currentContent.language?.name ?? 'no';
            api.getAncestors(currentContent).then((ancestors) => {
                const currentDomainPage = ancestors.find((ancestor) => ancestor.contentType.includes('OldDomainPage'));

                if (currentDomainPage) {
                    setDomainPage(currentDomainPage as OldDomainPageType);
                    updateMenu(currentDomainPage as OldDomainPageType);
                }
            });
        };

        if (props.currentContent?.contentType.includes('OldDomainPage')) {
            setDomainPage(props.currentContent as OldDomainPageType);
            updateMenu(props.currentContent as OldDomainPageType);
        } else if (props.currentContent && (!domainPage || domainPage.language?.name !== language)) {
            getDomainPageFromAncestors(props.currentContent);
        }
    }, [api, domainPage, language, props.currentContent]);

    if (!domainPage || !mainMenu) {
        return null;
    }

    const hideDidYouFind = ShouldHideDidYouFind(props.currentContent);
    const norwegianCourtsLocaleText = t(`${General}.NorwegianCourts`, language);
    return (
        <>
            <SkipToContent language={currentLanguage ?? 'no'} />
            <GoToTopAnchor />
            <div className="page-wrapper old" style={context.isServerSideRendering() ? { visibility: 'hidden' } : {}}>
                <Helmet htmlAttributes={{ lang: currentLanguage }}>
                    <title>
                        {(currentContent?.heading?.value ? currentContent?.heading?.value : currentContent.name) +
                        norwegianCourtsLocaleText
                            ? ' | ' + norwegianCourtsLocaleText
                            : ''}
                    </title>
                    <meta
                        property="og:title"
                        content={
                            props.currentContent?.heading?.value
                                ? props.currentContent?.heading?.value
                                : props.currentContent?.name.toString()
                        }
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content={currentLanguage ?? 'no'} />
                    <meta property="og:description" content={getDescription(currentContent)} />
                    <meta property="description" name="description" content={getDescription(currentContent)} />
                    <link rel="shortcut icon" href="/spaview/app.html.spa/favicon.svg" type="image/svg+xml" />
                    <link rel="stylesheet" href="/StaticContent/oldDomstol/hoyesterett.min.css" />
                    <style>
                        {`
                            .supremeCourt .external-link svg {
                                height: 16px !important;
                                width: 16px !important;
                            }

                            .supremeCourt .container {
                                width: inherit;
                            }
                        `}
                    </style>
                </Helmet>
                <header className="page-header">
                    <div className="container">
                        <SiteTop domainPage={domainPage} currentPage={currentContent} />
                        <MainMenu domainPage={domainPage} currentPage={currentContent} menu={mainMenu} />
                    </div>
                    <OldBreadcrumbs currentPage={currentContent} />
                </header>

                <main className="container" id="main-content" tabIndex={-1}>
                    {props.children}
                </main>

                {!hideDidYouFind && (
                    <div className="layout-row">
                        <OldFeedback currentPage={currentContent} layoutSettings={layoutSettings} />
                    </div>
                )}

                {domainPage && <OldFooter domainPage={domainPage} />}
            </div>
            <GoToTopLink />
        </>
    );
};

export default HoyesterettLayout;
